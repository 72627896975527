<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('MENU.api_internal_configurations') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>

      <div class="card-body">
        <b-card-body class="p-0">
          <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('title') }}</label>
              <input v-model="data.title" :class="validation && validation.title ? 'is-invalid' : ''"
                     :placeholder="$t('title')"
                     class="form-control"
                     type="text"/>
              <span v-if="validation && validation.title" class="fv-plugins-message-container invalid-feedback">
                {{ validation.title[0] }}
              </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('main_link') }}</label>
              <input v-model="data.main_link" :class="validation && validation.main_link ? 'is-invalid' : ''"
                     :placeholder="$t('main_link')"
                     class="form-control"
                     type="url"/>
              <span v-if="validation && validation.main_link" class="fv-plugins-message-container invalid-feedback">
                {{ validation.main_link[0] }}
              </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('method_type') }}</label>
              <div class="input-group">
                <select id="protocol" v-model="data.method" class="custom-select" name="">
                  <option v-for="row in method_type_list" :key="row.id" :value="row.id">
                    {{ row.title }}
                  </option>
                </select>
                <span v-if="validation && validation.method" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.method[0] }}
                </span>
              </div>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('security_key') }}</label>
              <input v-model="data.security_key" :class="validation && validation.security_key ? 'is-invalid' : ''"
                     :placeholder="$t('security_key')"
                     class="form-control"
                     type="text"/>
              <span v-if="validation && validation.security_key" class="fv-plugins-message-container invalid-feedback">
                {{ validation.security_key[0] }}
              </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('roles.description') }}</label>
              <textarea v-model="data.description" :class="validation && validation.description ? 'is-invalid' : ''"
                        :placeholder="$t('roles.description')"
                        class="form-control"
                        rows="3"></textarea>
              <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                {{ validation.description[0] }}
              </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox v-model="data.is_active" name="check-button" size="lg" switch>{{ $t('status') }}
              </b-form-checkbox>
            </div>

          </div>
        </b-card-body>
        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button class="btn btn-primary mr-2" type="button" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {

  data() {
    return {
      mainRoute: 'settings/api_internal_configurations',
      mainRouteDependency: 'base/dependency',
      idEdit: this.$route.params.id ? this.$route.params.id : '',
      data: {
        title: null,
        main_link: null,
        method: null,
        security_key: null,
        description: null,
        is_active: false,
      },
      isEditing: false,
      validation: null,
      method_type_list:[],
    };
  },
  watch: {},
  methods: {
    save() {
      let method = this.idEdit ? 'put' : 'post';
      let url = this.idEdit ? this.mainRoute + '/' + this.idEdit : this.mainRoute;
      ApiService[method](url, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/api-internal-configurations');
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data = {
          title: response.data.data.title,
          main_link: response.data.data.main_link,
          method: response.data.data.method,
          security_key: response.data.data.security_key,
          description: response.data.data.description,
          is_active: response.data.data.is_active,
        }
      });
    },
    getMethodList(){
      ApiService.get(this.mainRouteDependency + '/method_type').then((response) => {
        this.method_type_list = response.data.data;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("MENU.api_internal_configurations"), route: '/settings/api-internal-configurations'},
      {title: (this.idEdit ? this.$t('update') : this.$t('create'))}
    ]);
    if (this.idEdit) {
      this.getData();
    }
  },
  created() {
    this.getMethodList();
  }
};
</script>


